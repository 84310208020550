import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormikHelpers} from "formik";
import type {Dispatch, UnknownAction} from "redux";
import * as Yup from "yup";

import {mb, w100} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

import {IRPStore} from "../../app/rp_reducer";
import {FragmentFallbackSuspense} from "../../atoms/FragmentFallbackSuspense";
import {afterInternalAuth} from "../../auth/actions/after_internal_auth";
import {emailSignUp} from "../../auth/api/email_sign_up";
import {authAnalytics, GAAuthEventCategory, GAAuthEventName} from "../../auth/tracking/auth_analytics";
import {validationMessages} from "../../form/utils/validation_messages";
import {emptyLeadStorageData} from "../../lead/constants/empty_lead_storage_data";
import {leadButtonCopy} from "../../lead/constants/lead_button_copy";
import {ISentLeadActions, setSentLeadValuesAction} from "../../lead/reducers/sent_lead_reducer";
import {ISentLeadStoredValues} from "../../lead/types/ISentLeadStoredValues";
import {gtmFormRegister} from "../../tracking/google_tag_manager/gtm_event_form_register";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {forceFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserConsent} from "../../user/api/patch_user_consent";
import {MarketingConsentType} from "../../user/ts/enums/MarketingConsentType";
import {UserAccountType} from "../../user/ts/enums/UserAccountType";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {useEmailVerification} from "../hooks/use_email_verification";
import {NotificationConsentField} from "./application_form/fields/NotificationConsentField";
import {EmailVerificationPopover} from "./EmailVerificationPopover";

interface IProps {
    buttonText?: string;
    onAfterSubmit: () => void;
    onSubmitButtonClick?: () => void;
    onSuccessfulSubmit?: () => void;
    rodoTerms?: React.ReactNode;
}

const defaultInitialValues = {
    email: "",
    new_property_notification_consent: false
};

type ApplicationRegisterFormValuesType = typeof defaultInitialValues;

export const validationSchema = Yup.object({
    email: Yup.string().required(validationMessages.required).email(validationMessages.email)
});

export const ApplicationRegisterForm = (props: IProps) => {
    const dispatch: Dispatch<ISentLeadActions | UnknownAction> = useDispatch();

    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    /*
     * Logged-in user can select consents only when both email and phone consents are falsy.
     * If one of the two or both - email or phone - consents are truthy we assume that the user has edited consents.
     * Anonymous user always can select consent.
     */
    const canUserSelectConsent = userProfileData ? !userProfileData.email_marketing_consent && !userProfileData.phone_marketing_consent : true;

    const sentLead = useSelector((state: IRPStore) => state.sentLead.storedValues);

    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();

    const initialValues = getInitVals(userProfileData, sentLead);

    const onSubmit = async (values: ApplicationRegisterFormValuesType, formikHelpers: FormikHelpers<ApplicationRegisterFormValuesType>) => {
        if (!(await validateEmailOnce(values.email))) {
            return;
        }

        // User is logged in
        if (userProfileData) {
            if (userProfileData.email === values.email) {
                dispatch<ISentLeadActions>(setSentLeadValuesAction(values));

                if (values.new_property_notification_consent && !userProfileData.email_marketing_consent && !userProfileData.phone_marketing_consent) {
                    // User selected `new_property_notification_consent` field - we can update his profile data
                    await patchUserConsent({[MarketingConsentType.EMAIL]: true, [MarketingConsentType.PHONE]: true});

                    await dispatch(forceFetchUserProfile());
                }

                props.onAfterSubmit();
                formikHelpers.setSubmitting(false);

                return;
            }
        }

        emailSignUp({...values, type: UserAccountType.LOCAL})
            .then(() => {
                // Fully update storage data with new values - we have new user and old data cannot be used
                dispatch(setSentLeadValuesAction({...emptyLeadStorageData, ...values}));

                dispatch(afterInternalAuth(true, UserSource.APPLICATION));

                gtmFormRegister();
                authAnalytics.gtm.emailAuthEvent(GAAuthEventName.SIGN_UP, GAAuthEventCategory.LEAD_FORM);

                if (props.onSuccessfulSubmit) {
                    props.onSuccessfulSubmit();
                }
            })
            /*
             * Error from `emailSignUp` is an information that typed email is already used or request failed.
             * In such situation we just need to open application modal and store email in LS for future use.
             */
            .catch(() => {
                dispatch<ISentLeadActions>(setSentLeadValuesAction(values));
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);

                props.onAfterSubmit();
            });
    };

    return (
        <FormikForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            <EmailVerificationPopover {...emailNotificationProps} popoverPlace="left">
                <FormikForm.Input name="email" type="email" placeholder="Email" css={mb(3)} />
            </EmailVerificationPopover>

            {props.rodoTerms}

            <FragmentFallbackSuspense>
                {!initialValues.new_property_notification_consent && canUserSelectConsent && (
                    <NotificationConsentField name="new_property_notification_consent" css={mb(3)} />
                )}
            </FragmentFallbackSuspense>

            <FormikForm.Button css={w100} type="submit" variant="filled_primary" onClick={props.onSubmitButtonClick}>
                {props.buttonText || leadButtonCopy}
            </FormikForm.Button>
        </FormikForm>
    );
};

function getInitVals(userProfileData: IUserProfile | null, sentLead: ISentLeadStoredValues | null): ApplicationRegisterFormValuesType {
    const userData = userProfileData || sentLead;

    if (userData && userData.email) {
        return {
            email: userData.email,
            /*
             * For logged-in user show new_property_notification_consent field only when both email and phone consents are falsy.
             * For anonymous user always show new_property_notification_consent field.
             */
            new_property_notification_consent: userProfileData ? userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent : false
        };
    }

    return defaultInitialValues;
}
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormikHelpers} from "formik";
import type {Dispatch, UnknownAction} from "redux";
import * as Yup from "yup";

import {mb, w100} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

import {IRPStore} from "../../app/rp_reducer";
import {FragmentFallbackSuspense} from "../../atoms/FragmentFallbackSuspense";
import {afterInternalAuth} from "../../auth/actions/after_internal_auth";
import {emailSignUp} from "../../auth/api/email_sign_up";
import {authAnalytics, GAAuthEventCategory, GAAuthEventName} from "../../auth/tracking/auth_analytics";
import {validationMessages} from "../../form/utils/validation_messages";
import {emptyLeadStorageData} from "../../lead/constants/empty_lead_storage_data";
import {leadButtonCopy} from "../../lead/constants/lead_button_copy";
import {ISentLeadActions, setSentLeadValuesAction} from "../../lead/reducers/sent_lead_reducer";
import {ISentLeadStoredValues} from "../../lead/types/ISentLeadStoredValues";
import {gtmFormRegister} from "../../tracking/google_tag_manager/gtm_event_form_register";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {forceFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserConsent} from "../../user/api/patch_user_consent";
import {MarketingConsentType} from "../../user/ts/enums/MarketingConsentType";
import {UserAccountType} from "../../user/ts/enums/UserAccountType";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {useEmailVerification} from "../hooks/use_email_verification";
import {NotificationConsentField} from "./application_form/fields/NotificationConsentField";
import {EmailVerificationPopover} from "./EmailVerificationPopover";

interface IProps {
    buttonText?: string;
    onAfterSubmit: () => void;
    onSubmitButtonClick?: () => void;
    onSuccessfulSubmit?: () => void;
    rodoTerms?: React.ReactNode;
}

const defaultInitialValues = {
    email: "",
    new_property_notification_consent: false
};

type ApplicationRegisterFormValuesType = typeof defaultInitialValues;

export const validationSchema = Yup.object({
    email: Yup.string().required(validationMessages.required).email(validationMessages.email)
});

export const ApplicationRegisterForm = (props: IProps) => {
    const dispatch: Dispatch<ISentLeadActions | UnknownAction> = useDispatch();

    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    /*
     * Logged-in user can select consents only when both email and phone consents are falsy.
     * If one of the two or both - email or phone - consents are truthy we assume that the user has edited consents.
     * Anonymous user always can select consent.
     */
    const canUserSelectConsent = userProfileData ? !userProfileData.email_marketing_consent && !userProfileData.phone_marketing_consent : true;

    const sentLead = useSelector((state: IRPStore) => state.sentLead.storedValues);

    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();

    const initialValues = getInitVals(userProfileData, sentLead);

    const onSubmit = async (values: ApplicationRegisterFormValuesType, formikHelpers: FormikHelpers<ApplicationRegisterFormValuesType>) => {
        if (!(await validateEmailOnce(values.email))) {
            return;
        }

        // User is logged in
        if (userProfileData) {
            if (userProfileData.email === values.email) {
                dispatch<ISentLeadActions>(setSentLeadValuesAction(values));

                if (values.new_property_notification_consent && !userProfileData.email_marketing_consent && !userProfileData.phone_marketing_consent) {
                    // User selected `new_property_notification_consent` field - we can update his profile data
                    await patchUserConsent({[MarketingConsentType.EMAIL]: true, [MarketingConsentType.PHONE]: true});

                    await dispatch(forceFetchUserProfile());
                }

                props.onAfterSubmit();
                formikHelpers.setSubmitting(false);

                return;
            }
        }

        emailSignUp({...values, type: UserAccountType.LOCAL})
            .then(() => {
                // Fully update storage data with new values - we have new user and old data cannot be used
                dispatch(setSentLeadValuesAction({...emptyLeadStorageData, ...values}));

                dispatch(afterInternalAuth(true, UserSource.APPLICATION));

                gtmFormRegister();
                authAnalytics.gtm.emailAuthEvent(GAAuthEventName.SIGN_UP, GAAuthEventCategory.LEAD_FORM);

                if (props.onSuccessfulSubmit) {
                    props.onSuccessfulSubmit();
                }
            })
            /*
             * Error from `emailSignUp` is an information that typed email is already used or request failed.
             * In such situation we just need to open application modal and store email in LS for future use.
             */
            .catch(() => {
                dispatch<ISentLeadActions>(setSentLeadValuesAction(values));
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);

                props.onAfterSubmit();
            });
    };

    return (
        <FormikForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            <EmailVerificationPopover {...emailNotificationProps} popoverPlace="left">
                <FormikForm.Input name="email" type="email" placeholder="Email" css={mb(3)} />
            </EmailVerificationPopover>

            {props.rodoTerms}

            <FragmentFallbackSuspense>
                {!initialValues.new_property_notification_consent && canUserSelectConsent && (
                    <NotificationConsentField name="new_property_notification_consent" css={mb(3)} />
                )}
            </FragmentFallbackSuspense>

            <FormikForm.Button css={w100} type="submit" variant="filled_primary" onClick={props.onSubmitButtonClick}>
                {props.buttonText || leadButtonCopy}
            </FormikForm.Button>
        </FormikForm>
    );
};

function getInitVals(userProfileData: IUserProfile | null, sentLead: ISentLeadStoredValues | null): ApplicationRegisterFormValuesType {
    const userData = userProfileData || sentLead;

    if (userData && userData.email) {
        return {
            email: userData.email,
            /*
             * For logged-in user show new_property_notification_consent field only when both email and phone consents are falsy.
             * For anonymous user always show new_property_notification_consent field.
             */
            new_property_notification_consent: userProfileData ? userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent : false
        };
    }

    return defaultInitialValues;
}
