/* eslint-disable @typescript-eslint/no-explicit-any */
import {ApplicationSource} from "./ApplicationSource";

export enum InquiryHeaderTypeName {
    OFFER = "Zapytaj o ofertę",
    PRICE = "Zapytaj o cenę",
    PRICES = "Zapytaj o ceny",
    RESERVATION = "Powiadom o zwolnieniu rezerwacji",
    ARRANGE_MEETING = "Umów spotkanie",
    REVEAL_PRICES = "Odkryj cenę"
}

export const getPropertyApplicationSource = (property: any) => {
    //TODO: Should be IDesktopPropertyDetail
    if (property.is_reserved) {
        return ApplicationSource.Reservation;
    }

    return ApplicationSource.Property;
};

export const generateHeaderText = (source: ApplicationSource) => {
    switch (source) {
        case ApplicationSource.Offer:
        case ApplicationSource.Property:
        case ApplicationSource.PropertyAutoresponderRevealedPrices:
        case ApplicationSource.OfferAutoresponderRevealedPrices:
            return InquiryHeaderTypeName.OFFER;
        case ApplicationSource.PriceInquiry:
            return InquiryHeaderTypeName.PRICE;
        case ApplicationSource.OfferPriceInquiry:
            return InquiryHeaderTypeName.PRICES;
        case ApplicationSource.Reservation:
            return InquiryHeaderTypeName.RESERVATION;
        case ApplicationSource.RevealedPrices:
            return InquiryHeaderTypeName.REVEAL_PRICES;
        case ApplicationSource.ApplicationOfferScheduleMeeting:
        case ApplicationSource.ApplicationPropertyScheduleMeeting:
            return InquiryHeaderTypeName.ARRANGE_MEETING;
        default:
            return InquiryHeaderTypeName.OFFER;
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import {ApplicationSource} from "./ApplicationSource";

export enum InquiryHeaderTypeName {
    OFFER = "Zapytaj o ofertę",
    PRICE = "Zapytaj o cenę",
    PRICES = "Zapytaj o ceny",
    RESERVATION = "Powiadom o zwolnieniu rezerwacji",
    ARRANGE_MEETING = "Umów spotkanie",
    REVEAL_PRICES = "Odkryj cenę"
}

export const getPropertyApplicationSource = (property: any) => {
    //TODO: Should be IDesktopPropertyDetail
    if (property.is_reserved) {
        return ApplicationSource.Reservation;
    }

    return ApplicationSource.Property;
};

export const generateHeaderText = (source: ApplicationSource) => {
    switch (source) {
        case ApplicationSource.Offer:
        case ApplicationSource.Property:
        case ApplicationSource.PropertyAutoresponderRevealedPrices:
        case ApplicationSource.OfferAutoresponderRevealedPrices:
            return InquiryHeaderTypeName.OFFER;
        case ApplicationSource.PriceInquiry:
            return InquiryHeaderTypeName.PRICE;
        case ApplicationSource.OfferPriceInquiry:
            return InquiryHeaderTypeName.PRICES;
        case ApplicationSource.Reservation:
            return InquiryHeaderTypeName.RESERVATION;
        case ApplicationSource.RevealedPrices:
            return InquiryHeaderTypeName.REVEAL_PRICES;
        case ApplicationSource.ApplicationOfferScheduleMeeting:
        case ApplicationSource.ApplicationPropertyScheduleMeeting:
            return InquiryHeaderTypeName.ARRANGE_MEETING;
        default:
            return InquiryHeaderTypeName.OFFER;
    }
};
